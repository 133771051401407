.header-bg {
  width: 100%;
  height: 100vh;
  background: #fff;
  margin:1% auto;
}

body{
  background: #fff;
}

/* #2b367f */