@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');
.testimonial{
    margin: 5% 5% !important;
}
.testimonial .slick-arrow.slick-prev,
.testimonial .slick-arrow.slick-next {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
}
.testimonial .slick-prev::before,
.testimonial .slick-next::before {
  display: none;
}
.testimonial .slick-arrow.slick-next {
  right: -70px;
}
.testimonial .slick-arrow.slick-prev {
  left: -70px;
}
.testimonial .slick-dots li button:before {
  font-size: 15px;
}
.testimonial .slick-dots li {
  top: 15px;
}

.title h2 {
    font-size: 4rem;
    font-weight: 400;
    line-height: 1.4;
    text-transform: uppercase;
    color: var(--dark-text);
    margin: 1.5rem 0;
  }
  
  .testimonial-content{
    font-weight: 300;
    line-height: 1.4;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    text-justify: inter-word;
    color:#343A40;
    text-decoration:none solid rgb(52, 58, 64);
    font-size:12px;
    
  }
