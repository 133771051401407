.intro_image{

    width: 49%;

}
.intro_image img{
    max-height: 33vh;
    margin: 0 auto;
}
.intro_content{
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.intro_content h1{

    font-size: 42px;
    line-height: 58px;

}
.hr-line{
    border-bottom: 6px solid #0E146B;
    width: 30%;
    margin-bottom: 30px;
}
.partner_butn{
    padding: 14px 55px 14px 25px;
    color: #000000;
    background-size: 31px;
    background-position: 93% 47%;
    text-decoration: none;
    width: 44%;
    border-radius: 30px;
    margin-top: 30px;
}
.Partner_image-a img{
    max-width: 185px;
    margin: 0 auto;

}
.benifits{
        /* text-align: center; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}
.benifits .hr-line{
    width: 20%;
    margin-top: 10px;

}
.benifit-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10%;
    background-color: #0E146B;
    height: 100%;
    border-radius: 10px;
    color: #fff;
}

.benifit-card img{
    width: 100%;
    height: auto;

}

.benifit-card-text{
    text-align: center;
    padding: 0 14px;
    font-size: 14px;
}
.benifit-card-title{
    margin:30px 0;
    font-size: 20PX;
}
/* .benifit-background-color{
    background-color: #0E146B;
} */
.benifits-head-title{
    font-size: 4rem;
  font-weight: 400;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--dark-text);
  margin: 1.5rem 0;
}
.benifits-head-text{
    color: grey;
    font-size: 20px;
}
.become-partner-butn{
    background: yellow;
    color: #000000;
    display: inline-block;
    font-size: 16px;
    line-height: normal;
    text-align: center;
    padding: 14px 25px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
    min-width: 160px;
    letter-spacing: 1px;
    overflow: hidden;
    border: 1.5px solid yellow;
    transition: all .2s ease;
    z-index: 1;
}
.about_partner{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.about_partner-section .hr-line{

    width: 20%;    
    text-align: center;
    margin-top: 20px;
    background-color: #F5F5F5;
    
}
.about_partner-item img{
    width: 70%;
}
.about_partner-item-content{
flex-direction: column;
align-items: center;
text-align: center;
padding: 10px;
position: relative;
bottom: 140px;
display: flex;
color: transparent;
height: 0;
    
}
.box{
    height: 180px;
    animation-duration: 0.8s;
    animation-fill-mode: both;
    animation-name: fadeInBottom;
    transition: linear 0.2s all;
    margin:5PX 2px 2px 2px;
}
.box:hover > .about_partner-item{
    opacity: 0.1;
}

.box:hover > .about_partner-item-content{
    color: #fff;
    padding: 35px;
}
.box:hover{
    background-color: #0E146B;
    box-shadow: 0px 6px 24px #00000042;
    border-radius: 12px;
}

.sell-section{
    padding: 2rem;
    background-color: #F5F5F5;
}
.sell-title{
    margin-top:50px ;
    font-size: 16px;
    font-weight: 100;
    color: #000000;
}
.sell-section .sell-row .row{
    padding: 3rem;
}
.sell-section .sell-row .row ul{
    list-style-type:disc;
    color: #FFE701;
    font-weight: 700;
}
.ecomm-b  {
    border-bottom: 10px solid #FFE701;
    width: 25%;
    align-items: center;
    margin-left: 36%;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  .opportunities{
    width: 100%;
  }
