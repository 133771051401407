@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');
section#subscribe { 
  background: url(../../assets/subscribe-bg.png);
  background: white;
  text-align: center;
  color: #1f2469;
  background-size: cover;
  background-attachment: fixed;
}

.subscribe h2 {
  font-size: 3rem;
  font-weight: 200;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 1.5rem 0;
  padding-bottom: 1rem;
}

form > * {
  width: 100%;
  max-width: 50rem;
  margin: 3rem auto;
}

form input {
  font-family: 'Montserrat', sans-serif;
  padding: 1rem;
  background: transparent;
  border: 1.4px solid #1f2469;
  border-radius: 5px;
  color: #1f2469;
  outline: none;
  width: 80%;
  font-size: 1.6rem;
}

select  {
  width: 50%;
  max-width: 50rem;
  margin: 3rem auto;
 
}

.form-control{
  width: 80% !important;
  padding: 0.375rem 5.75rem;
  display: inline-block !important;
  font-size: 16px !important;
  margin-bottom: 4%;

}

select option {
  font-family: 'Montserrat', sans-serif;
  padding: 1rem;
  background: transparent;
  border: 1.4px solid #1f2469;
  border-radius: 5px;
  color: #1f2469;
  outline: none;
  width: 80%;
  font-size: 1.6rem;
}

form button {
  padding: 1rem;
  background: var(--form-button);
  border: 1px solid var(--form-button);
  border:1.4px solid #1f2469; 
  background: white;
  border-radius: 5px;
  color:#1f2469;
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  margin: 2rem;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  border: 1px solid #1f2469;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s;
}

.social-icon:hover {
  background: var(--light-blue);
}



.img-fluid {
    max-width: 100%;
    height: auto;
  
}

.img-fluid1 {
  max-width: 100%;
  height: auto;
  box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) !important;
}


#b_partner .img-box {
  padding: 40px 40px 10px 40px;
  position: relative;
}

textarea{
  font-family: 'Montserrat', sans-serif;
    padding: 1rem;
    background: transparent;
    border: 1.4px solid #1f2469;
    border-radius: 5px;
    color: #1f2469;
    outline: none;
    width: 80%;
    font-size: 1.6rem;
}

#b_partner .img-box i {
  font-style: normal;
  text-align: center;
  font-weight: bold;
  background: white;
  padding: 3px;
  font-size: 15px;
  color: #1f2469;
  display: block;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 9%;
  left: 45%;
}
.b_partner-header .b_title {
  text-align: center;
  color: #333;
  line-height: 1.6;
  position: relative;
  margin-top: 5px;
  font-size: small;
}

.section-title{
  font-size: 4rem;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: var(--dark-text);
  margin: 0.5rem 0;
}

.b_partner-header .b_title span {
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  form input {
    width: 60%;
  }
}

#zosideimage{
  width: 50vw;
  margin:0px;
  position: absolute;
  right: 0px;
  top: 200px;
}

.wrapper{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background-container{
  width: 100%;
  min-height: 100vh;
  display: flex; 
}
.bg-1{
  flex: 1;
  background-color: rgb(180, 243, 175);
}
.bg-2{
  flex: 1;
  background-color: rgb(163, 236, 240);
}
.about-container{
  width: 85%;
  min-height: 80vh;
  position: absolute;
  background-color: white;
  box-shadow: 24px 24px 30px #6d8dad;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  border-radius: 5px;
}
.image-container{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-container img {
  width: 500px;
  height: 500px;
  margin: 20px;
  border-radius: 10px;
}
.text-container{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-size: 22px;
}
.text-container h1{
  font-size: 70px;
  padding: 20px 0px;
}
.text-container a{
  text-decoration: none;
  padding: 12px;
  margin: 50px 0px;
  background-color: rebeccapurple;
  border: 2px solid transparent;
  color: white;
  border-radius: 5px;
  transition: .3s all ease;
}
.text-container a:hover{
  background-color: transparent;
  color: black;
  border: 2px solid rebeccapurple;
}
@media screen and (max-width: 1600px){
  .about-container{
      width: 90%;
  }
  .image-container img{
      width: 400px;
      height: 400px;
  }
  .text-container h1{
      font-size: 50px;
  }
}
@media screen and (max-width: 1100px){
  .about-container{
      flex-direction: column;
  }
  .image-container img{
      width: 300px;
      height: 300px;
  }
  .text-container {
      align-items: center;
  }
}

.circle-background{
  fill: none;
  stroke: #ddd;
}

.circle-progress{
  fill: none;
  stroke: #ddd;
}

textarea{
  font-family: 'Montserrat', sans-serif;
  padding: 1rem;
  background: transparent;
  border: 1.4px solid #1f2469;
  border-radius: 5px;
  color: #1f2469;
  outline: none;
  width: 80%;
  font-size: 1.6rem;
}

@media screen and (max-width: 600px) {
  .queryContent{
      width: 275px;
  }
}
