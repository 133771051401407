.ResellCarousel-head{
  margin: 0rem 0;
  padding: 2.5rem;
  width: 100%;}

  .title-carousel h2 {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.4;
    text-transform: uppercase;
    color: var(--dark-text);
    margin: 1.5rem 0;
  }
  