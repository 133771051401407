@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

section#footer {
  background: #2b367f;
  color: #fff;
}

.footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  row-gap: 2rem;
  column-gap: 2rem;
}

/* .footer > * {
  flex: 1;
  justify-self: center;
} */

.footer h4 {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.4;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.footer-box .footer-links a {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 1.5rem;
  display: flex;
  font-weight: 500;
  align-items: center;
  margin-bottom: 5px;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-links a{
  font-family: 'Montserrat', sans-serif !important;
  text-decoration: none;
  color: #fff;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 1.5rem;

}

.footer-contact p {
  font-family: 'Montserrat', sans-serif !important;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 1.5rem;
  font-weight: 500;

}
.copy{
  position: relative;

}

.zobizlogo{
  width: 30vh;
  height: auto;
  margin-left: 8rem;
  top: 2%;
}

.copyright{
  font-family: 'Montserrat', sans-serif !important; 
  position: absolute;
  text-align: center;
  font-size:15px ;
  margin: auto 10% ;
  width: 80vw;
  top:1rem;

}
