/* .Carousel-head{
  margin: 0;
  padding: 0rem;
  width: 100%;
  } */

.Carousel-head{
  margin-top: 80px;
  background: #fff;
  width: 100%;
}