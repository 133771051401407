  section#subscribe { 
  background: url(../../assets/subscribe-bg.png);
  background: white;
  text-align: center;
  color: #1f2469;
  background-size: cover;
  background-attachment: fixed;
}

.subscribe h2 {
  font-size: 3rem;
  font-weight: 200;
  line-height: 1;
  text-transform: uppercase;
  padding-bottom: 1rem;
}

form > * {
  width: 80%;
  max-width: 50rem;
  margin: 3rem auto;
}

form input {
  padding: 1rem;
  background: transparent;
  border: 2px solid #1f2469;
  border-radius: 5px;
  color: black;
  outline: none;
  width: 80%;
  font-size: 1.6rem;
}

form button {
  padding: 1rem;
  background: var(--form-button);
  border: 1px solid var(--form-button);
  border:1px solid #1f2469; 
  background: white;
  border-radius: 5px;
  color:#1f2469;
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  margin: 2rem;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  border: 1px solid #1f2469;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s;
}

.social-icon:hover {
  background: var(--light-blue);
}



.img-fluid {
    max-width: 100%;
    height: auto;
  
}

.img-fluid1 {
  max-width: 100%;
  height: auto;
  box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) !important;
}


#b_partner {
  width: 95%;
  margin: 5% auto;

}
#b_partner .img-box {
  padding: 40px 40px 10px 40px !important;

  position: relative;
}
#b_partner .img-box i {
  font-style: normal;
  text-align: center;
  font-weight: bold;
  background: white;
  padding: 3px;
  font-size: 15px;
  color: #1f2469;
  display: block;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 9%;
  left: 45%;
}
.b_partner-header .b_title {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  line-height: 1.4;
  font-family: 'Poppins', sans-serif;
  text-align: justify;
  text-justify: inter-word;
  color: #1f2469!important;
}

.section-title{
  font-size:30px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight:700 !important;
  line-height: 1.4 !important;
  text-transform: uppercase !important;
  color: #2b367fbf !important;
  line-height:36px !important;
}

.b_partner-header .b_title span {
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  form input {
    width: 60%;
  }
}

#sideimage{
  width: 100%;
  height: auto;
  
}