
.container {
    margin-top: 60px;
    margin-bottom: 20px;
}

.zobox {
    padding: 0% !important;
    font-size: 14px;
}

.card {
    width: 100%;
    margin-top: 3%;
}

/* .col-left{
    position: relative;
}

.col-right{
    position: absolute;
} */

p{
    font-size: 16px;
}

h3{
    font-size: 18px;
}

.image {
    max-width: 750px;
}
.blogcard{
    margin-right: 60px;
}





