/* @import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500;600&family=Poppins:wght@100&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

/* font-family: 'Jost', sans-serif; */
body{
  background: #fff;
}
.title {
  text-align: center;
  margin-bottom: 4rem;
}


.features{
  margin: 0 7%;
}
#features{
  background: #fff !important;
}
.title h2 {
  font-size:30px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight:700 !important;
  line-height: 1.4 !important;
  text-transform: uppercase !important;
  color: #2b367fbf !important;
  line-height:36px !important;
}

.title p {
  width: 50%;
  min-width: 40rem;
  margin: 0 auto;
  color: var(--dark-text);
  font-family: 'Poppins', sans-serif;
}

.features-content {
  display: flex;
  justify-content: center;
  /* padding: 3rem 0; */
}

.features-left {
  flex: 1;
  text-align: center;
}
.features-left img {
  width: 70%;
  height: auto;
  margin:-1% auto;
}
.features-right {
  flex: 1;
}


.overflow{
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .features-content {
    flex-direction: column;
  }
}
