@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

#down_app h1{
    font-size: 50px;
    font-weight: 700;
    color:#2b367f ;
}


#download_app{
    padding-top: 0% !important;

}

#down_app .content p {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;
    color: rgb(132, 130, 130);
}
p {
    font-weight: 400;
    margin: 0px;

}
#down_icon a img {
    width: 25%;
}
#down_app{
    margin-top: 30%;
}

.img-fluid{
    width: 100%;
}