

.card{
    width:20rem;
}

.card:hover{
    box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) !important;
}

.card-body{
    padding: 3rem 0 !important;
}

.card-text{
    font-size: 0.9rem;
    padding: 0.4rem 1.9rem;
}


.overflow{
    overflow: hidden;
}
.card-img-top{
    transform: scale(1);
    transition:transform 0.5s ease;
}

.card-img-top:hover{
    transform: scale(1.2);
}
.title h2 {
    font-size: 3rem;
    font-weight: 400;
    line-height: 0;
    text-transform: uppercase;
    color: var(--dark-text);
    margin: 0rem 0;
  }
  
  .NewsCarousel-head{
  margin: 15rem 0;
  padding: 2.5rem;
  width: 100%;}

